import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import BgImage from "../images/bg_factory.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="TOP" />
    <div>
      <section>
        <MainCopy className="font-mincho">
          <span className="inline-block bg-primary p-4 tracking-widest text-white">
            不燃内装材の
          </span>
          <br />
          <span className="inline-block bg-primary p-4 text-white">
            プロフェッショナル
          </span>
        </MainCopy>
        <div className="lg:text-right">
          <StaticImage
            src="../images/top/concept.jpg"
            alt="様々な不燃内装材"
            className="-z-10 mt-0 md:mt-24 lg:mt-48 lg:w-2/3"
          />
        </div>
      </section>
      <section>
        <div className="mt-24 md:mt-48 md:flex">
          <MobileWrapper>
            <StaticImage
              src="../images/top/eyecatch.jpg"
              alt="アートマテリアルのロゴが彫り込まれたパネル"
            />
          </MobileWrapper>
          <div className="my-auto mx-8 lg:w-1/2">
            <span className="font-mincho text-xs uppercase text-primarySupport">
              Company Profile
            </span>
            <h1 className="font-mincho text-3xl text-primary">
              アートマテリアルについて
            </h1>
            <p className="mt-4 font-gothic leading-loose text-neutral">
              アートマテリアルは内装制限下でも利用可能なゾノトライト系けい酸カルシウム「タイカライトウッド」を材料とした空間を彩る不燃内装材を創り続けてまいりました。
              <br />
              <br />
              お客様からのご要望を元に、適切な加工方法のご提案だけではなく、小ロットからの製造・予算・納期のご相談にも柔軟にお応えしております。
            </p>
            <Link to="/profile">
              <span className="block text-right font-gothic underline underline-offset-1">
                詳しく
              </span>
            </Link>
          </div>
        </div>
      </section>
      <WideBg className="relative mt-48 bg-secondery pb-16">
        <VarticalHeadWrapper className="absolute -top-16 flex">
          <VarticalHead className="font-mincho text-3xl tracking-widest text-primary">
            製品紹介
          </VarticalHead>
          <VarticalHeadSupport className="font-mincho text-xs uppercase text-primarySupport">
            Product
          </VarticalHeadSupport>
        </VarticalHeadWrapper>
        <div className="grid w-full grid-cols-2 gap-3 lg:mr-0 lg:ml-auto lg:w-2/3 lg:gap-6 ">
          <Link to="/products/molding">
            <div className="relative mt-32">
              <StaticImage
                src="../images/products/molding.jpg"
                alt="モールディング"
                className="m-auto w-full"
              />
              <span className="absolute -bottom-0 left-0 inline-block bg-primary py-2 px-6 font-mincho text-sm text-white lg:left-8 lg:-bottom-4 lg:text-base">
                モールディング
              </span>
            </div>
          </Link>
          <Link to="/products/louver">
            <div className="relative mt-32">
              <StaticImage
                src="../images/products/louver.jpg"
                alt="ルーバー"
                className="m-auto w-full"
              />
              <span className="absolute -bottom-0 left-0 inline-block bg-primary py-2 px-6 font-mincho text-sm text-white lg:-bottom-4 lg:left-8 lg:text-base">
                ルーバー
              </span>
            </div>
          </Link>
          <Link to="/products/mikiri">
            <div className="relative">
              <StaticImage
                src="../images/products/mikiri.jpg"
                alt="見切材"
                className="m-auto w-full"
              />
              <span className="absolute -bottom-0 left-0 inline-block bg-primary py-2 px-6 font-mincho text-sm text-white lg:-bottom-4 lg:left-8 lg:text-base">
                見切り
              </span>
            </div>
          </Link>
          <Link to="/products/panel">
            <div className="relative">
              <StaticImage
                src="../images/products/panel.jpg"
                alt="デザインパネル"
                className="m-auto w-full"
              />
              <span className="absolute -bottom-0 left-0 inline-block bg-primary py-2 px-6 font-mincho text-sm text-white lg:-bottom-4 lg:left-8 lg:text-base">
                デザインパネル
              </span>
            </div>
          </Link>
          <div className="relative">
            <StaticImage
              src="../images/products/sign.jpg"
              alt="様々な不燃内装材"
              className="m-auto w-full"
            />
            <span className="absolute -bottom-0 left-0 inline-block bg-primary py-2 px-6 font-mincho text-sm text-white lg:-bottom-4 lg:left-8 lg:text-base">
              サイン
            </span>
          </div>
        </div>
        <div className="mt-16 w-full rounded bg-white py-8 px-16 lg:mr-0 lg:ml-auto lg:w-2/3 ">
          <h3 className="font-mincho text-lg">不燃認定について</h3>
          <div className="my-4 font-gothic text-sm">
            <span>国内</span>
            <p className="mt-2">
              タイカライトウッド・タイカライトウッドFXは大臣認定不燃材料であると共に
              平成12年建設省告示第1400号により定められる告示指定の不燃材料です。
            </p>
          </div>
          <div className="my-4 font-gothic text-sm">
            <span>中国</span>
            <p className="mt-2">
              中国国家基準 GB8624-2012
              の「建築材料および製品の燃焼特性等級」において
              タイカライトウッドはA1クラス、タイカライトウッドFXはA2クラスに合格しております。
            </p>
          </div>
        </div>
        <div className="mt-16 w-full rounded bg-white py-8 px-16 lg:mr-0 lg:ml-auto lg:w-2/3 ">
          <h3 className="font-mincho text-lg">原材料について</h3>
          <div className="my-4 font-gothic text-sm">
            <p className="mt-2">
              日本インシュレーション株式会社が提供する「タイカライトウッド」という無機素材を使用しております。
              <br />
              加工性に優れ、匂わない、腐らない、伸縮がほぼないことが特徴です。
              内装制限が求められる部位にも安心してご利用いただけます。
            </p>
          </div>
          <Link to="/materials">
            <span className="block text-right font-gothic underline underline-offset-1">
              詳しく
            </span>
          </Link>
        </div>
      </WideBg>
      <SkewBgColor className="relative overflow-hidden">
        <VarticalHeadWrapperRight className="mt-8 flex">
          <VarticalHead className="h-40 font-mincho text-3xl tracking-widest text-primary">
            設備紹介
          </VarticalHead>
          <VarticalHeadSupport className="font-mincho text-xs uppercase text-primarySupport">
            Facility
          </VarticalHeadSupport>
        </VarticalHeadWrapperRight>
        <div className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
          <div className="relative mt-16 lg:flex">
            <StaticImage
              src="../images/facility/saw1.jpg"
              alt="工場の様子"
              className="m-auto w-full lg:w-1/2"
            />
            <div className="my-auto w-full rounded bg-white py-8 px-16 lg:relative lg:top-48 lg:-left-8 lg:w-1/2">
              <p className=" font-gothic leading-loose text-neutral">
                国内自社工場に配備されたCNCルーター・モルダー・ランニングソーから、どのように製品が加工されるのかご紹介いたします。
              </p>
              <Link to="/facility">
                <span className="block text-right font-gothic underline underline-offset-1">
                  詳しく
                </span>
              </Link>
            </div>
          </div>
        </div>
        <VarticalHeadWrapper className="mt-48 flex">
          <VarticalHead className="h-40 font-mincho text-3xl tracking-widest text-primary">
            納入実績
          </VarticalHead>
          <VarticalHeadSupport className="font-mincho text-xs uppercase text-primarySupport">
            Works
          </VarticalHeadSupport>
        </VarticalHeadWrapper>
        <div className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
          <div className="relative lg:flex">
            <div className="z-30  my-auto w-full rounded bg-white py-8 px-16 lg:relative lg:top-48 lg:w-1/2">
              <p className=" font-gothic leading-loose text-neutral">
                商業施設やホテル、公共施設など幅広く活用されています。
              </p>
              <Link to="/works">
                <span className="block text-right font-gothic underline underline-offset-1">
                  詳しく
                </span>
              </Link>
            </div>
            <StaticImage
              src="../images/top/narita.jpg"
              alt="納品事例 成田空港"
              className="relative w-full lg:-left-8 lg:w-1/2"
            />
          </div>
        </div>
      </SkewBgColor>
      <VarticalHeadWrapper className="-mt-24 mb-8">
        <span className="font-mincho text-xs uppercase text-primarySupport">
          News
        </span>
        <h2 className="font-mincho text-3xl text-primary">お知らせ</h2>
      </VarticalHeadWrapper>
      <BgImageWrapper className="w-full bg-cover py-16">
        <Link to="news">
          <div className="mr-0 ml-auto mt-24 w-full rounded bg-white lg:w-2/3">
            <div className="py-6 px-16">
              <span className="block font-gothic text-sm text-primary">
                2023.6.28
              </span>
              <h4 className="my-4 font-gothic text-neutral">
                ブランドロゴを一新しました。
              </h4>
            </div>
          </div>
        </Link>
      </BgImageWrapper>
    </div>
  </Layout>
)

const MainCopy = styled.h1`
  writing-mode: vertical-rl;
  font-size: calc(2rem + ((1vw - 8.68px) * 2.02));
  margin-right: 44%;
  margin-left: auto;
  margin-bottom: -30vw;
  padding-top: 10%;
  @media screen and (max-width: 1023px) {
    margin-left: 10%;
    margin-bottom: -40vw;
    z-index: -100;
    padding-top: 20%;
  }
`
const VarticalHead = styled.h2`
  writing-mode: vertical-rl;
`
const VarticalHeadWrapper = styled.div`
  margin-left: 30%;
  @media screen and (max-width: 1023px) {
    margin-left: 10%;
  }
`
const VarticalHeadWrapperRight = styled.div`
  margin-left: 80%;
`

const VarticalHeadSupport = styled.span`
  writing-mode: vertical-rl;
`
const WideBg = styled.section`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`
const SkewBgColor = styled.section`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  position: relative;
  padding-top: 204px;
  padding-bottom: 400px;
  ::before {
    width: 210%;
    height: 30%;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0x;
    background-color: #f5f5f3;
    transform: skewY(-45deg);
    z-index: -1;
  }
  @media screen and (max-width: 1023px) {
    padding: 102px 0 200px 0;
  }
`
const MobileWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`

const BgImageWrapper = styled.section`
  background-image: url(${BgImage});
  height: 460px;
`

export default IndexPage
